
  import { Component, Vue } from 'vue-property-decorator';
  import { strategies } from '@/types/enums';
  import { CREATE_BOTS } from '@/store/users';
  import { BotCreationData, BotStrategy, CompetitionBetType } from '@/types/entities';
  import { betTypes } from '@/types/enums';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterInput from '@/components/HipsterInput.vue';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';

  @Component({
    components: { HipsterInput, HipsterSelector, InspiniaIbox },
    mixins: [ validationMixin ],
    validations() {
      return {
        formData: {
          strategy: { required },
          count: { required },
          participation_probability: { required }
        }
      };
    }
  })
  export default class extends Vue {
    public selectedStrategy: BotStrategy = {
      name: '',
      value: strategies.monkey
    };

    public selectedBetTypes: CompetitionBetType[] = [];

    public formData: BotCreationData = {
      strategy: strategies.monkey,
      bet_types: [],
      count: 1,
      participation_probability: 100
    };

    get strategies(): BotStrategy[] {
      return [
        {
          name: this.$t(`users.strategies.${strategies.monkey}.name`),
          value: strategies.monkey
        },
        {
          name: this.$t(`users.strategies.${strategies.crowdFollower}.name`),
          value: strategies.crowdFollower
        },
        {
          name: this.$t(`users.strategies.${strategies.leaderFollower}.name`),
          value: strategies.leaderFollower
        },
        {
          name: this.$t(`users.strategies.${strategies.favouriteLover}.name`),
          value: strategies.favouriteLover
        },
        {
          name: this.$t(`users.strategies.${strategies.underdogLover}.name`),
          value: strategies.underdogLover
        },
      ];
    }

    get betTypes(): CompetitionBetType[] {
      return [
        { name: this.$t('betTypes.moneyline'), bet_type: betTypes.moneyline },
        { name: this.$t('betTypes.spread'), bet_type: betTypes.spread },
        { name: this.$t('betTypes.total'), bet_type: betTypes.total }
      ];
    }

    mounted() {
      this.selectedStrategy = this.strategies[0];
    }

    setSelectedBetType(selectedBetTypes: CompetitionBetType[]) {
      this.formData.bet_types = selectedBetTypes.map((betType) => betType.bet_type);
    }

    public addBots() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.$store.dispatch(CREATE_BOTS, this.formData)
        .then(() => this.$modal.hide('add-bots-modal'))
        .then(() => {
          this.$emit('completed');
        });
    }
  }
