
  import { Component, Vue } from 'vue-property-decorator';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import InspiniaGridItem from '@/components/InspiniaGridItem.vue';

  import { SearchUsersParams } from '@/types/search';
  import { roles, strategies } from '@/types/enums';
  import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
  import { SEARCH_USERS, RESET_STATE } from '@/store/users';
  import { BotStrategy, User } from '@/types/entities';
  import { betTypes } from '@/types/enums';
  import SearchResultsTable from '@/components/SearchResultsTable.vue';
  import AddBotsModal from '@/components/AddBotsModal.vue';

  @Component({
    components: {AddBotsModal, SearchResultsTable, HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaGridItem },
    computed: {
      users() {
        const betTypeNames = {
          [betTypes.moneyline]: this.$t('betTypes.moneyline'),
          [betTypes.spread]: this.$t('betTypes.spread'),
          [betTypes.total]: this.$t('betTypes.total'),
        };

        return this.$store.state.users.usersList.data
          .map((user: User): SimpleTableRowItem => {
            return {
              to: `/users/${user.id}`,
              line: [
                user.id as any as string,
                user.name,
                user.bot_strategy as string,
                user.bet_types!.map((type) => betTypeNames[type.bet_type]).join(', '),
                user.profile.participation_probability ? `${user.profile.participation_probability}%` : '100%',
                user.picks_count as any as string,
                user.profit as any as string,
                user.profile.is_active ? this.$t('yes') : this.$t('no')
              ]
            };
          });
      }
    }
  })
  export default class InsightsList extends Vue {
    get tableHeader(): SimpleTableHeaderItem[] {
      return [
        { class: 'col-lg-1', name: this.$t('bots.titles.id') },
        { class: 'col-lg-2', name: this.$t('bots.titles.username') },
        { class: 'col-lg-2', name: this.$t('bots.titles.strategy') },
        { class: 'col-lg-2', name: this.$t('bots.titles.betTypes') },
        { class: 'col-lg-2', name: this.$t('bots.titles.participationProbability') },
        { class: 'col-lg-1', name: this.$t('bots.titles.picks') },
        { class: 'col-lg-1', name: this.$t('bots.titles.profit') },
        { class: 'col-lg-1', name: this.$t('bots.titles.active') }
      ];
    }

    public filters: SearchUsersParams = {
      query: '',
      per_page: 10,
      page: 1,
      with_earned: 1,
      with_trashed: 1,
      with_count: ['picks'],
      with: ['bet_types'],
      role_id: roles.bot
    };

    get strategies(): BotStrategy[] {
      return [
        { name: this.$t('bots.strategies.all'), value: null },
        { name: this.$t('bots.strategies.monkey'), value: strategies.monkey },
        { name: this.$t('bots.strategies.crowdFollower'), value: strategies.crowdFollower },
        { name: this.$t('bots.strategies.leaderFollower'), value: strategies.leaderFollower },
        { name: this.$t('bots.strategies.favouriteLover'), value: strategies.favouriteLover },
        { name: this.$t('bots.strategies.underdogLover'), value: strategies.underdogLover }
      ];
    }

    public selectedStrategy: { name: string, value: strategies | null } = {
      name: 'All strategies',
      value: null
    };

    created() {
      this.$store.commit(RESET_STATE);

      this.search({});
    }

    public search(filter: SearchUsersParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.$store.dispatch(SEARCH_USERS, Object.assign(this.filters, filter));
    }
  }
